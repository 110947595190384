import i18n from '../common/plugins/vue-i18n'

export const MEDIA_PATH = '/media/'
export const DEFAULT_IMAGE_PATH =
  'https://new.amazingjackie.com/theme/trial_template_4/img/temp.png'

export const globalFunc = {
  DataValid(data) {
    if (data != undefined && data != null && data != '') {
      return true
    } else {
      return false
    }
  },
  formatMoney: function (number) {
    number = Math.round(number * 100) / 100

    let decPlaces = 2
    let decSep = '.'
    let thouSep = ','
    var sign = number < 0 ? '-' : ''
    var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))))
    var j = (j = i.length) > 3 ? j % 3 : 0

    return (
      sign +
      (j ? i.substr(0, j) + thouSep : '') +
      i.substr(j).replace(/(\decSep{4})(?=\decSep)/g, '$1' + thouSep) +
      (decPlaces ?
        decSep +
        Math.abs(number - i)
        .toFixed(decPlaces)
        .slice(2) :
        '')
    )
  },
  getServerImageHead() {
    return MEDIA_PATH
  },
  firstFileToBase64(fileImage) {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader()
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage)
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
        fileReader.onerror = error => {
          reject(error)
        }
      } else {
        reject(new Error('No file found'))
      }
    })
  },
  formatDate(d) {
    return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1}-${
      d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
    }`
  },
  getDateStr(date) {
    if (globalFunc.DataValid(date)) {
      return date.split('T')[0]
    }

    return ''
  },
  getTimeStr(date) {
    if (globalFunc.DataValid(date)) {
      return date.split('T')[1].replace('Z', '')
    }

    return ''
  },
  getDateTimeStr(date) {
    if (globalFunc.DataValid(date)) {
      return date.split('T')[0] + ' ' + date.split('T')[1].replace('Z', '')
    }

    return ''
  },
  subtractTime(time1, time2, days) {
    //format: mm:ss

    let time1_arr = time1.split(':')
    let time2_arr = time2.split(':')

    time1_arr = time1_arr.map(obj => {
      return parseInt(obj)
    })

    time2_arr = time2_arr.map(obj => {
      return parseInt(obj)
    })

    let hour = 0
    let min = 0

    if (time2_arr[1] < time1_arr[1]) {
      time2_arr[1] += 60
      time2_arr[0] -= 1
    }

    min = time2_arr[1] - time1_arr[1]
    hour = time2_arr[0] - time1_arr[0]

    let translate_to_min = hour * 60 + min

    translate_to_min *= days

    hour = Math.floor(translate_to_min / 60)
    min = translate_to_min - hour * 60

    let text = ''

    if (hour != 0 && min != 0) {
      text = `${hour} ${i18n.tc('general.hour', 0)} ${min} ${i18n.tc('general.minute', 2)}`
    } else if (hour != 0 && min == 0) {
      text = `${hour} ${hour == 1 ? i18n.tc('general.hour', 0) : i18n.tc('general.hour', 2)}`
    } else if (hour == 0 && min != 0) {
      text = `${min} ${min == 1 ? i18n.tc('general.minute', 0) : i18n.tc('general.minute', 2)}`
    }

    return [text, translate_to_min]
  },

  getAge(dateString) {
    let current = new Date().getTime();
    let age = new Date(dateString).getTime();
    let ageInMilliseconds = current - age;
    return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
  }
}

export const Formatter = {
  filter: {
    numOnly: function (s) {
      return s.replace(/[^0-9]/gi, '')
    },
    alphaOnly: function (s) {
      return s.replace(/[^a-z]/gi, '')
    },
    alphaNumeric: function (s) {
      return s.replace(/[^a-z0-9]/gi, '')
    },
    noWhiteSpace: function (s) {
      return s.replace(/\s/g, '')
    },
  },
  escape: {
    html: function (s) {
      return s
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
    },
    regex: function (s) {
      return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    },
  },
  convert: {
    byte: function (n, p) {
      if (n === 0) return '0B'
      var p = p || 1
      const k = 1024
      const s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(n) / Math.log(k))
      return parseFloat((n / Math.pow(k, i)).toFixed(p)) + s[i]
    }, // size int, precision (1)
  },
  replace: {
    linebreak: function (s, c) {
      return s.replace(/(?:\r\n|\r|\n)/g, c || '<br/>')
    }, // string, replace char
  },
  separator: function (n, c, l) {
    var l = l || 3
    var r = new RegExp('\\B(?=(\\d{' + l + '})+(?!\\d))', 'g')
    var p = n.toString().split('.')
    p[0] = p[0].replace(r, c || ',')
    return p.join('.')
  }, // string, separator char (,) , separator mask length (3)
  pad: function (n, l, c, r, b) {
    var s = n.toString(),
      l = l || 2,
      r = r !== false,
      b = b !== false
    if (s.length > 0) {
      var p = ''
      if (!isNaN(n) && s.charAt(0) == '-') {
        p = '-'
        s = s.substring(1)
      }
      while (s.length < l) {
        if (r) s = (c || '0') + s
        else s += c || '0'
      }
      return b ? p + s : s
    } else return ''
  }, // number, length (2), padder char (0), isPadLeft (YES), showNegative (YES)
}

export const FormValidator = {
  required_username: value => globalFunc.DataValid(value) || '請輸入帳號',
  required_password: value => globalFunc.DataValid(value) || '請輸入密碼',
  required_upload: value =>
    globalFunc.DataValid(value) || `${i18n.t('error.force_upload')} ${i18n.t('error.document')}`,
  required_date: value => globalFunc.DataValid(value) || '請選擇日期',
  //required: value => !!value || `${i18n.t('error.force_input')} ${i18n.t('error.content')}`,
  required: value => globalFunc.DataValid(value),
  email: value => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) ||
      `${i18n.t('error.invalid')} ${i18n.t('worker.email')} ${i18n.t('error.format')}`
  },
  mobile: value => {
    const pattern = /^[2-9][0-9]{7}$/
    return (
      pattern.test(value) ||
      `${i18n.t('error.invalid')} ${i18n.t('worker.mobile_number')} ${i18n.t('error.format')}`
    )
  },
  isNumberOnly: value => {
    const pattern = /^[0-9]+$/i
    return pattern.test(value) ||
      `${i18n.t('error.force_input')} ${i18n.t('error.number')} ${i18n.t('error.only')}`
  },
  isFloating: value => {
    const pattern = /^[0-9]+[.]?[0-9]+$/i
    return pattern.test(value) ||
      `${i18n.t('error.force_input')} ${i18n.t('error.floating')} ${i18n.t('error.only')}`
  },
}

export const CheckInputValidate = {
  email: value => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value)
  },
  mobile: value => {
    const pattern = /^[2-9][0-9]{7}$/
    return pattern.test(value)
  },
  isNumberOnly: value => {
    // true = number only
    const pattern = /^[0-9]+$/i
    return pattern.test(value)
  },
  isFloating: value => {
    const pattern = /^[0-9]+[.]?[0-9]+$/i
    return pattern.test(value)
  },
  compareYearMonth: (year1, month1, year2, month2) => {
    // return 1 = first > second
    // return 0 when first = second
    // return -1 when first < second

    let firstYear = parseInt(year1)
    let firstMonth = parseInt(month1)
    let secondYear = parseInt(year2)
    let secondMonth = parseInt(month2)
    if (firstYear > secondYear) {
      return 1
    } else if (firstYear < secondYear) {
      return -1
    } else {
      if (firstMonth > secondMonth) {
        return 1
      } else if (firstMonth < secondMonth) {
        return -1
      } else {
        return 0
      }
    }
  },
}
