// for data that does not need to store consistently

import axios from 'axios'
import { globalFunc } from './global'

const axiosURI = '/backend/develop/request.php'

export const getData = async params => {
  const response = await axios.post(axiosURI, params.toString())
  let result = response.data
  if (result.result == 'success') {
    if (globalFunc.DataValid(result.total)) {
      return Promise.resolve({ data: result.data, total: result.total })
    }
    return Promise.resolve(result.data)
  }
  return Promise.reject(result.data)
}

export const postData = async (params, config = null) => {
  let response = await axios.post(axiosURI, params.toString())
  if (config != null) {
    response = await axios.post(axiosURI, params.toString(), config)
  }

  let result = response.data
  if (result.result == 'success') {
    if (globalFunc.DataValid(result.total)) {
      return Promise.resolve({ data: result.data, total: result.total })
    }
    return Promise.resolve(result.data)
  }
  return Promise.reject(result)
}
